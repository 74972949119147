export const Environment = {
	AppMeta: {
		Environment: 'TEST',
	},
	AuthConfig: {
		// ZEISS ID stage environment
		issuer: 'https://b2cstage.zeiss.com/483f449b-6a0d-4354-b7e7-37113e7d4ca1/v2.0/',
		clientId: 'ac5fb875-7d36-4c5a-9112-c12c4d09621f',
		discoveryDocumentUrl:
			'https://id-ip-stage.zeiss.com/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdNormalSignIn',
		selfServicePortalUrl: 'https://id-stage.zeiss.com/',
	},
	API: {
		Path: 'https://func-dlp-test-westeu-002.azurewebsites.net/api',
		Headers: {
			Authorization: '',
		},
		Params: {},
		Create: {
			Path: 'https://func-eop-ac-test-westeu-002.azurewebsites.net/api',
			Headers: {
				'x-functions-key': '9Uw0WamMe9cGJo01RoxlRgiYHaBAP1IMOgo0sVAc098FAzFuA3aQHQ==',
			},
			LegalDocument: '/legal-document',
			TermsOfUse: '/Developer%20TermsofUse%20Form%20v2%20(2-6-23)%20(002).pdf',
			Authorization: '/Developer%20Authorization%20Form%20v2%20(2-6-23)_with_hyperlinks.pdf',
		},
		Whitelist: {
			Path: 'https://esb-test.zeiss.com',
			AddUser: '/public/api/experience/vis/evt/account-whitelist/user',
			AddUsers: '/public/api/experience/vis/evt/account-whitelist/users',
			DownloadCsv: '/public/api/experience/vis/evt/account-whitelist/users',
			Headers: {
				'Ocp-Apim-Subscription-Key': '1741d0e0903b4790b6d37fa602625b85',
			},
		},
	},
	RXPassUploadMaxFileSizeinBytesBinary: 8388608,
	RXPassUploadMaxFileSizeinMBText: '8MB',
	appInsights: {
		activated: true,
		instrumentationKey: '53459b1e-45ce-415e-bf57-95d08a5e3764',
	},
	AzureAppConfig: {
		ConnectionString: 'Endpoint=https://appcs-evt-test-westeu.azconfig.io;Id=5P7e-l9-s0:UQMOwueAMashNWUApncZ;Secret=j9z8jqG04Ng+9+FuR/Jc/MZU8OVPcomsMV5DJvXM2sk=',
	},
};
